<template>
  <section id="card-actions">
    <b-row>
      <!-- card collapsible  -->
      <b-col md="12">
        <billing-information
          :data="billing[0]"
          :is-loading="isLoading"
        ></billing-information>
      </b-col>

      <!-- card refresh -->
      <b-col md="12">
        <billing-summary
          :data="billing[0]"
          :is-loading="isLoading"
        ></billing-summary>
      </b-col>

      <!-- card remove -->
      <b-col md="12">
        <last-billing-summary
          :data="billing"
          :is-loading="isLoading"
        ></last-billing-summary>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

import BillingInformation from "./BillingIinformation.vue";
import BillingSummary from "./BillingSummary.vue";
import LastBillingSummary from "./LastBillingSummary.vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    ToastificationContent,
    BillingInformation,
    BillingSummary,
    LastBillingSummary,
  },
  data() {
    return {
      billing: [],
      isLoading: false,
    };
  },
  methods: {
    init: function() {
      this.isLoading = true;
      axios
        .get("billing/data")
        .then((response) => {
          this.billing = response.data.invoices;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Billing",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Billing",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Billing",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
