<template>
  <b-card-actions v-if="data" title="BILLING SUMMARY" action-collapse>
    <b-card-body>
      <div v-if="isLoading">
        <b-row>
          <b-col md="12"> <loading></loading> </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col md="2"></b-col>
          <b-col md="8">
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon
                        size="24"
                        :icon="'FileTextIcon'"
                      /> </b-avatar
                  ></b-col>
                  <b-col md="4">Total Amount Due</b-col>
                  <b-col md="6"
                    >IDR {{ formatPrice(data.total_amount_due) }}</b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon
                        size="24"
                        :icon="'FileTextIcon'"
                      /> </b-avatar
                  ></b-col>
                  <b-col md="4">Previous Balance</b-col>
                  <b-col md="6"
                    >IDR {{ formatPrice(data.previous_balance) }}</b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon
                        size="24"
                        :icon="'CalendarIcon'"
                      /> </b-avatar
                  ></b-col>
                  <b-col md="4">Billing Period</b-col>
                  <b-col md="6">{{ data.bill_period }}</b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Loading from "@core/components/loading/Loading.vue";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Loading,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    removeMinus(value) {
      return value.replace("-", "");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
