<template>
  <b-card-actions v-if="data" title="SUMMARY BILLING 3 MONTH" action-collapse>
    <b-card-body>
      <div v-if="nowLoading">
        <loading></loading>
      </div>
      <div v-else>
      <b-row>
          <b-col lg="4">
            <b-form-group>
              <h5>Select Date</h5>
              <div class="input-group">
                <flat-pickr
                  v-model="filterDate"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
             
              <div class="input-group-append">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                  @click="find"
                >
                  <feather-icon icon="ArchiveIcon" />
                </b-button>
              </div>
               </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table responsive :items="data" :fields="fields" bordered>
          <template #cell(total_amount_due)="data">
            IDR {{ formatPrice(data.value) }}
          </template>
          <template #cell(payment)="data">
            <template v-if="data.item.status_payment == '2'">
              <b-badge pill variant="light-success"> Paid </b-badge>
            </template>
            <template v-else>
              <b-badge pill variant="light-danger"> Unpaid </b-badge>
            </template>
          </template>

          <template #cell(action)="data">
            <b-link class="nav-link" @click="download(data.item.invoice_no)">
              <feather-icon icon="FileTextIcon" size="21" />
            </b-link>
          </template>
        </b-table>
      </div>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Loading from "@core/components/loading/Loading.vue";
import axios from "axios";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
  BBadge
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Loading,
    BLink,
    BBadge
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: null,
      fields: [
        { key: "bill_period", label: "BILL PERIOD" },
        { key: "due_date", label: "DUE DATE" },
        { key: "invoice_no", label: "NO INVOICE" },
        { key: "total_amount_due", label: "TOTAL CHARGE" },
        { key: "payment", label: "PAYMENT" },
        { key: "action", label: "DOWNLOAD" },
      ],
    };
  },
  computed: {
    nowLoading() {
      return this.loading === null ? this.isLoading : this.loading;
    },
  },
  methods: {
    removeMinus(value) {
      return value.replace("-", "");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    download: function (invoice) {
      //window.open();
      const clean_invoice = invoice.split("/");
      this.loading = true;
      
      // --TESTING DOANG. SILAKAN DI HAPUS--
      //clean_invoice[1] = '20210032058';
      // --------------------------
      axios({
        url: "https://api-portal.netciti.co.id/api-invoice/pdf.php?invoice=" + clean_invoice[1],
        method: "GET",
        //headers: { "Access-Control-Allow-Origin": "*" },
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", invoice + ".pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
