<template>
  <b-card-actions v-if="data" title="PERSONAL INFORMATION" action-collapse>
    <b-card-body>
      <div v-if="isLoading">
        <b-row>
          <b-col md="12"> <loading></loading> </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col md="2"></b-col>
          <b-col md="8">
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon size="24" :icon="'UserIcon'" /> </b-avatar
                  ></b-col>
                  <b-col md="4">Customer ID</b-col>
                  <b-col md="6">{{ data.customer_id }}</b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon size="24" :icon="'UserIcon'" /> </b-avatar
                  ></b-col>
                  <b-col md="4">Customer Name</b-col>
                  <b-col md="6">{{ getName }}</b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon
                        size="24"
                        :icon="'SmartphoneIcon'"
                      /> </b-avatar
                  ></b-col>
                  <b-col md="4">Handphone</b-col>
                  <b-col md="6">{{ data.telp }}</b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="1">
                    <b-avatar size="24" :variant="'light-info'"
                      ><feather-icon
                        size="24"
                        :icon="'MapPinIcon'"
                      /> </b-avatar
                  ></b-col>
                  <b-col md="4">Address</b-col>
                  <b-col md="6">{{ data.address }}</b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Loading from "@core/components/loading/Loading.vue";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Loading,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    getName: function () {
      return localStorage.getItem("name");
    }
  },
};
</script>
